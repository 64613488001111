

.villasInfo a{
    font-weight: bolder;
    padding:5px;
    margin: 5px;
}
.custom-toggler img{
    width: 30px;
    height: 30px;
}

.navbar {
  z-index: 4;
  width: 100%;
  height: 90px;
  transition: all ease 0.5s;
}

.navbar-toggler {
  box-shadow: none !important;
}
.navLinks {
  background-color: antiquewhite;
}
.navLinks a {
  font-size: 18px;
  font-weight: 500;
}
.navLinks .active{
  background-color:rgb(158, 228, 158);
  border-radius: 5px;
}
.sticky-nav {
  background-color: rgb(248, 248, 247) !important;
}
.logo {
  width: 200px;
}
.transp {
  background-color: transparent !important;
}
@media screen and (max-width:425px) and (min-width:320px){
    .villa-logo-top{
        width: 150px;
        height:40px;
    }   
    .logo {
      width: 150px;
    }
}
@media screen and (min-width: 992px) {
  .navLinks {
    background-color: transparent;
  }
}
