
.propertyImage {
  max-width: 1300px;
  object-fit: cover;
  height: 400px;
}
.detailsBlock {
  padding: 20px;
  width: 100%;
  grid-template-columns: 1fr;
}
.detailsBlock p{
  word-break:keep-all;
  max-height: 100px;
  overflow: hidden;
  padding: 8px;
}

.additionalInfoBlock {
  padding: 20px;
  border: 1px solid rgb(211, 211, 211);

}
.additionalInfoBlock h6,
.additionalInfoBlock p {
  font-size: 13px;
  word-break: break-all;
  width: 170px;
}
.additionalInfoBlock h6 {
  width: 50%;
}
.additionalInfoBlock h3 {
    text-decoration: underline;
}
.viewBtn{
  text-decoration: underline;
  cursor: pointer;
  width:150px;
}

.contactusIndetail{
  width: 80%;
  background-color: #dad6d6;
  font-family: 'Quicksand', sans-serif;
}
.iconsDecoration{
  font-size: 30px;
  color: white;
}
.abtProperty{
  text-decoration: underline;
}
.abtSeller{
  background-color: #f2f2f2;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(136, 136, 136, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(126, 126, 126, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(146, 146, 146, 0.75);
}
.abtSeller h5{
    font-size: 13px;
}
.abtSeller h2{
  font-size: 15px;
  text-decoration: underline;
}
.villaBackBtn{
  width: 50px;
  height: 50px;
  border-radius: 50%;

}

.viewImagesBtn{
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .detailsBlock {
    grid-template-columns: 500px 500px;
  }
  .additionalInfoBlock h6 , .additionalInfoBlock p {
    width: 300px;
    font-size: 18px;
    font-weight: bolder;
  }
  .abtSeller h5{
    font-size: 20px;
}
.abtSeller h2{
  font-size: 25px;
  text-decoration: none;
}
}
@media screen and (min-width: 992px) {
  .detailsBlock {
    grid-template-columns: 400px 400px 400px;
  }
  .propertyImage1 {
    width: 700px;
    height: 300px;
  }
  .propertyImage2,
  .propertyImage3 {
    width: 300px;
    height: 150px;
  }
  .viewMoreImage,
  .viewMoreButton {
    width: 300px;
    height: 150px;
  }
  .viewMoreButton {
    background-color: rgba(245, 245, 245, 0.3);
    border: 0;
  }
}
@media screen and (min-width: 1400px) {
  .imagesBlock {
    max-width: 1300px;
  }

  .propertyImages {
    width: 380px;
    height: auto;
  }

  .detailsBlock {
    border: 1px solid rgb(214, 211, 211);
    padding: 30px;
    grid-template-columns: 320px 320px 320px 320px;
    width: 1300px;
    justify-content: space-between;
  }

  .additionalInfoBlock {
    font-family: 'Quicksand', sans-serif;
    width: 1300px;
    box-shadow: 3px 4px 10px rgb(224, 219, 219);
  }
}
