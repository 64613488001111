.villaCardsBlock {
    max-width: 1200px;
  }
  .villaCard {
    box-shadow: 0.3px 0.1px 20px rgb(155, 153, 153);
    border-radius: 8px;
    width: 18rem;
    height:28rem;
  }
  .villaCard img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .desc{
    height: 70px;
  }
  .buyBtn {
    width: 120px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 2px 2px 10px grey;
    background-color: rgb(102, 60, 180);
  }
  .locationPin {
    font-size: 15px;
    opacity: 0.8;
  }
  .areablock {
    background-color: rgba(95, 158, 160, 0.5);
  }
  .villaCardsGrid{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
 
  @media screen and (min-width: 992px) {
    .villaCard {
      width: 19rem;
    }
  }
  @media screen and (min-width:320px) and (max-width:425px) {
    .villaCardsGrid{
      grid-template-columns: 1fr;
    }
    
  }
  @media screen and (min-width:426px) and (max-width:768px) {
    .villaCardsGrid{
      grid-template-columns: 1fr 1fr;
    }
    
  }
  @media screen and (min-width:769px) and (max-width:1024px) {
    .villaCardsGrid{
      grid-template-columns: 1fr 1fr 1fr;
    }
    
  }