.wr_loginForm {
  background-image: url("../../Assests/loginForm-min.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.loginForm {
  background-color: rgb(255, 255, 255);
  width: 280px;
  /* height: 500px; */
  padding: 20px;
  border-radius: 10px;
}
.loginInput {
  border: 1px solid grey;
}
.loginInput:focus {
  outline: black;
}
@media screen and (min-width: 576px) {
  .loginForm {
    width: 350px;
  }
}
@media screen and (min-width: 768px) {
  .loginForm {
    width: 500px;
    padding: 40px;
  }
}
