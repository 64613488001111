body,html{
  height: 100%;
  width: 100%;
}
.landing-page {
    position: relative;
  }
  .row{
    height: 900px
  }
  .buySellAnimation{
    top:-200px;
    animation: buysell 2s ease  forwards;
  }
  .uparrowButton{
    right: 0;
    margin: 0px 10px 10px 0px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: all ease 1s;
    z-index: 3;
  }
  @keyframes buysell {
    0%{
        transform: translateY(0px);
    }
    100%{
        transform: translateY(200px);
    }
  }
  .text-container {
    height: 200px;
    z-index: 1;
    font-family: 'Fredericka the Great', serif;
  }
  .text-container h1 {
    font-size: 9rem;
  }
  .getStartedButton{
    background-color: aqua;
    border-radius: 5px;
    width: 100px;
    padding: 10px;
    font-family: 'Poiret One', sans-serif;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(136, 136, 136, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(126, 126, 126, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(146, 146, 146, 0.75);
  }
  .getStartedBtn{
    z-index: 2;
    max-width: 100px;
  }
  .background-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }




  .btn41-43 {
    padding: 10px 25px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    background: #351d3a;
    outline: none !important;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .btn-42 {
    /* border: 2px solid rgb(255, 255, 255); */
    z-index: 1;
    color: white;
    font-weight: bold;
  }
  
  .btn-42:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: rgb(255, 255, 255);
    transition: all 0.3s ease;
  }
  
  .btn-42:hover {
    color: rgb(0, 0, 0);
  }
  
  .btn-42:hover:after {
    top: 0;
    height: 100%;
  }
  
  .btn-42:active {
    top: 2px;
  }







  
  @media screen and (max-width:425px) and (min-width:320px) {
    .text-container{
        padding: 10px;
        height: 100px;
    }
    .text-container h1 {
        font-size: 3rem;
      }
      .row{
        height: 500px
      }
      .buySellAnimation{
        top:-200px;
  }
  @keyframes buysell {
    0%{
        transform: translateY(0px);
    }
    100%{
        transform: translateY(200px);
    }
  }
  }

  @media screen and (max-width:922px) and (min-width:426px) {
 
    .text-container{
      height: 100px;
  }
    .text-container h1 {
        font-size: 3.5rem;
      }
      .row{
        height: 500px
      }
  }