
.sellWrapper {
    width: 100%;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('../../Assests/sellImage-min.jpg');
  }
  
  .sellRegisForm {
    min-width: 500px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #eff1ec;
    font-family: 'Poiret One', sans-serif;
  }

  .sellRegisForm h2 {
    font-family: 'Quicksand', sans-serif;
  }
  
  .sellRegisForm div {
    margin-bottom: 15px;
    font-size: 18px;
  }
  .sellRegisForm label {
    margin-bottom: 5px;
  }
  .sellRegisForm input,
  .sellRegisForm textarea,
  .sellRegisForm select {
    padding: 10px;
    border: 1px solid #b8b2b2;
    border-radius: 5px;
    background-color: transparent;
    font-family: sans-serif;
    resize: none;
  }
  .sellRegisForm span{
    cursor: pointer;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
    }

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

  /* Additional styles for responsiveness */
  @media screen and (min-width:100px) and (max-width:522px){ 
    .sellRegisForm {
        min-width: 300px;
    } 
    .sellRegisForm h2 {
      font-size: 20px;
    }
    .sellRegisForm div {
      margin-bottom: 10px;
      font-size: 16px;
      margin: 4px;
    }
    .sellWrapper {
      background-image:none;
    }
  } 
