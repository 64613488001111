.reasonstoPartner{
    width: 350px;
    height: 500px;
    border-radius: 10%;
}
.reasons ul li{
 padding:10px;
 margin: 5px;
 font-size: 19px;
 font-family: 'Quicksand', sans-serif;
}
.reasons ul{
    padding: 10px 60px 0px 60px;
}
.reasonsHeading h3{
    font-size: 35px;
    max-width:32rem;
}
.whyChooseUs{
    padding: 40px;
    width: 100%;
}
@media screen and (max-width:768px) and (min-width:320px){
    .whyChooseUs{
        padding: 0px 30px 0px 30px;
    }
    .reasonstoPartner{
        width: 250px;
        height: 350px;
    }
    .reasonsHeading h3{
        margin-top:50px ;
        font-size: 25px;
        max-width: 35rem;
        padding:0px !important;
    }
    .reasons ul{
        padding: 0px;
    }
}

@media screen and (max-width:375px) and (min-width:200px){
    .reasonsHeading h3{
        font-size: 20px;
    }
}

