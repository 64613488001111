.form-control{
    min-width: 350px;
    border: 1px solid rgb(81, 143, 148) !important;
    resize: none;
}
.form-label{
    font-family: 'Quicksand', sans-serif;
}
.contactusImage{
    border-radius: 30px;
}
.villaFormWrapper{
    font-family: 'Poiret One', sans-serif;
}
.credit{
    font-weight: 100;
    font-size: 9px;
    color: rgb(246, 246, 248);
}
::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  .contactusImage{
    width: 800px;
    height:450px;
 }
 .villaFormWrapper div input,.villaFormWrapper div textarea{
    font-weight: bold;
    font-family: 'Quicksand', sans-serif;
 }
 .contactFormWrapper{
    background-color:#dad6d6 ;
    padding:0px 20px ;
 }

@media screen and (min-width:320px) and (max-width:425px) {
     .form-control{
        min-width: 280px;
     }    
     .form-control{
        border: 0px solid rgb(81, 143, 148) !important;
     }
} 
@media screen and (max-width:1439px) and (min-width:768px) {
    .contactusImage{
        overflow: hidden;
        width: 570px;
        height: 370px;
     }
} 
@media screen and (max-width:3000px) and (min-width:769px) {
    .contactFormWrapper{
        background-color:white;
     }
}