.wr_registrationForm {
  background-image: url("../../Assests/registrationForm-min.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.Registrationform {
  background-color: white;
  padding: 20px;
  max-width: 500px;
}
.Registrationform label {
  margin-bottom: 5px;
}
.errorClass {
  font-size: 15px;
}
.textArea {
  resize: none;
  height: 140px;
}
.inputBox,
.textArea {
  border: 1px solid grey;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}
@media screen and (min-width: 768px) {
  .Registrationform {
    max-width: 600px;
  }
}
@media screen and (max-width:425px) and (min-width:320px){
  .wr_registrationForm {
    background-image: none;
  }
}
