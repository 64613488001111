.villaServices{
    width: 100%;
    /* background-color: #eff1ec; */
    background-color: #fff2e7;
}
.services-container {
    width:55%;
}
.villaServices h2{
    font-size: 50px;
    font-family: 'Quicksand', sans-serif;
}
 .service-item {
  font-size: 15px;
  margin: 15px;
  width: 170px;
  height: 170px;
}
.service-icon {
  font-size: 2.5rem;
  color: #522826;
}
.service-name {
  font-size: 1rem;
  font-family: 'Quicksand', sans-serif;
}
@media screen and (max-width:1024px) and (min-width:768px) {
  .service-item {
    margin: 15px;
    width: 140px;
    height: 140px;
  }
  
}